import { Layout, notification } from 'antd';
import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
} from 'firebase/auth';
import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AuthorizedRoute from './AuthorizedRoute';
import { UnitSelector, Waiting } from './components';
import { BIG_SCREEN, NORMAL_SCREEN, SMALL_SCREEN, TINY_SCREEN } from './configs';
import { useCatalogSlice } from './features/catalogs/store';
import { selectSchoolsHandling } from './features/catalogs/store/selectors';
import { Footer, Paths } from './features/layout/components';
import { useSubjectSlice } from './features/subjects/store';
import { useUserSlice } from './features/user/store';
import { selectUserHandling, selectUserInformation } from './features/user/store/selectors';

const Header = lazy(() => import('./features/layout/components/Header'));
const Sider = lazy(() => import('./features/layout/components/Sider'));
const NotFoundPage = lazy(() => import('./pages/NotFound'));
const LoginPage = lazy(() => import('./pages/Login'));
const HomePage = lazy(() => import('./pages/Surveys'));
const StaffsPage = lazy(() => import('./pages/Staffs'));
const SchoolsPage = lazy(() => import('./pages/Schools'));
const ReligionsPage = lazy(() => import('./pages/Religions'));
const EthnicsPage = lazy(() => import('./pages/Ethnics'));
const UnitsPage = lazy(() => import('./pages/Units'));
const AnalyticsPage = lazy(() => import('./pages/Analytics'));
const ReportConfigsPage = lazy(() => import('./pages/ReportConfigs'));
const SourcesPage = lazy(() => import('./pages/Sources'));
const SubjetsPage = lazy(() => import('./pages/Subjects'));
const AnalyticsManagementPage = lazy(() => import('./pages/AnalyticsManagement'));
const DocumentsPage = lazy(() => import('./pages/Documents'));
const CrossSubjectsPage = lazy(() => import('./pages/CrossSubjects'));
const EditModal = lazy(() => import('./components/EditModal'));

function App() {
  const isTinyScreen = useMediaQuery(TINY_SCREEN);
  const isNormalScreen = useMediaQuery(SMALL_SCREEN);
  const isSmallScreen = useMediaQuery(NORMAL_SCREEN);
  const isBigScreen = useMediaQuery(BIG_SCREEN);
  const dispatch = useDispatch();
  const userInformation = useSelector(selectUserInformation);
  const userHandling = useSelector(selectUserHandling);
  const schoolsHandling = useSelector(selectSchoolsHandling);
  const { actions } = useUserSlice();
  const [editModal, setEditModal] = useState();
  useCatalogSlice();
  useSubjectSlice();
  const theme = useMemo(
    () => ({
      isTinyScreen,
      isNormalScreen,
      isSmallScreen,
      isBigScreen,
    }),
    [isTinyScreen, isNormalScreen, isSmallScreen, isBigScreen]
  );

  useEffect(() => {
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        notification.warning({
          message: 'Link không hợp lệ',
          description: 'Link đã được mở trên một thiết bị khác. Xin vui lòng đăng nhập lại',
        });
      } else {
        signInWithEmailLink(getAuth(), email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
          })
          .catch((err) => {
            notification.error({
              message: 'Lỗi',
              description: err.message,
            });
          });
      }
    }
    return onAuthStateChanged(getAuth(), (user) => {
      dispatch(
        actions.signedIn(
          user?.uid && user?.email
            ? {
                uid: user.uid,
                email: user.email,
                displayName: user?.displayName ?? '',
                photoURL: user?.photoURL ?? '',
              }
            : null
        )
      );
    });
  }, [actions, dispatch]);

  const signedIn = useMemo(
    () => Boolean(userInformation?.email && userInformation?.activeUnit),
    [userInformation]
  );

  if (userInformation && !userInformation?.activeUnit) {
    return <UnitSelector />;
  }

  return (
    <BrowserRouter>
      {userHandling || schoolsHandling ? <Waiting /> : null}
      <Suspense fallback={<Waiting />}>
        <ThemeProvider theme={theme}>
          <Layout>
            <EditModal editModal={editModal} closeEditModal={() => setEditModal()} />
            {signedIn && <Header />}
            <Layout.Content>
              <Layout>
                {signedIn && <Sider openEditModal={(modal) => setEditModal(modal)} />}
                <Layout.Content>
                  <Layout>
                    {signedIn && <Paths />}
                    <Layout.Content
                      style={
                        signedIn
                          ? {
                              overflow: 'auto',
                              height: window.innerHeight - 130,
                              background: '#f0f2f5',
                              padding: '10px 20px',
                            }
                          : {}
                      }
                    >
                      <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route
                          path="/"
                          element={
                            <AuthorizedRoute>
                              <HomePage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/dieutra/doituong"
                          element={
                            <AuthorizedRoute>
                              <SubjetsPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/dieutra/traituyen"
                          element={
                            <AuthorizedRoute>
                              <CrossSubjectsPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/catalog/staffs"
                          element={
                            <AuthorizedRoute>
                              <StaffsPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/catalog/schools"
                          element={
                            <AuthorizedRoute>
                              <SchoolsPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/catalog/religions"
                          element={
                            <AuthorizedRoute>
                              <ReligionsPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/catalog/ethnics"
                          element={
                            <AuthorizedRoute>
                              <EthnicsPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/catalog/units"
                          element={
                            <AuthorizedRoute>
                              <UnitsPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/analytics/reportManagement"
                          element={
                            <AuthorizedRoute>
                              <AnalyticsManagementPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/analytics/view/:id"
                          element={
                            <AuthorizedRoute>
                              <AnalyticsPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/analytics/view"
                          element={
                            <AuthorizedRoute>
                              <AnalyticsPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/management/reportConfigs"
                          element={
                            <AuthorizedRoute>
                              <ReportConfigsPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/management/sources"
                          element={
                            <AuthorizedRoute>
                              <SourcesPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="/thuvienvanban"
                          element={
                            <AuthorizedRoute>
                              <DocumentsPage />
                            </AuthorizedRoute>
                          }
                        />
                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                    </Layout.Content>
                  </Layout>
                </Layout.Content>
              </Layout>
            </Layout.Content>
            {signedIn && <Footer />}
          </Layout>
        </ThemeProvider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
